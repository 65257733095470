<template>
    <card-table-search :fields="fields"
                       :actions="actions"
                       :items="items"
                       :custom-rendered="['imageExtension', 'proprietaire']"
                       :exportColumns="fields.filter(item => item.key !== 'imageExtension')"
                       class="table-body-lh-40"
                       title="Liste des jeux">
        <template #title>
            Liste des jeux
            <b-button pill variant="success" size="sm" class="ml-2" v-b-tooltip.hover="'Ajouter un jeu'"
                      @click="editJeuModal(null)">
                <font-awesome-icon icon="plus"/>
            </b-button>
        </template>
        <template #imageExtension="slotProps">
            <img :src="thumbnailSrc(slotProps.row.item, 'jeux')" alt="Image du jeu" class="thumbnail-object-image"/>
        </template>
        <template #proprietaire="slotProps">
            <user-name :user="slotProps.row.item.proprietaire" default-cluji/>
        </template>
    </card-table-search>
</template>

<script>
    import {thumbnailSrc} from '@/util/image';
    import {apiPath}      from '@/util/http';
    import {textUsername} from '@/util/text';
    import alert          from '@/util/alert';

    const CardTableSearch   = () => import('@/components/CardTableSearch');
    const AdminEditJeuModal = () => import('@/components/modals/AdminEditJeuModal');
    const DisplayJeuModal   = () => import('@/components/modals/DisplayJeuModal');
    const UserName          = () => import('@/components/UserName');

    export default {
        name: "AdminJeux",
        components: {CardTableSearch, UserName},
        data() {
            return {
                fields: [
                    {
                        key: 'imageExtension',
                        label: 'Image',
                        sortable: false
                    },
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'joueursMin',
                        label: 'Joueurs min.',
                        sortable: true
                    },
                    {
                        key: 'joueursMax',
                        label: 'Joueurs max.',
                        sortable: true
                    },
                    {
                        key: 'caution',
                        label: 'Caution',
                        sortable: true,
                        formatter: value => value ? value + ' €' : ''
                    },
                    {
                        key: 'proprietaire',
                        label: 'Propriétaire',
                        sortable: true,
                        formatter: value => value ? textUsername(value) : '' // Only used for export, it's customRendered
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Afficher',
                        handler: ({item}) => this.displayJeuModal(item)
                    },
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editJeuModal(item)
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: "Supprimer",
                        handler: ({item}) => this.deleteJeu(item)
                    }
                ],
                items: []
            };
        },
        methods: {
            thumbnailSrc,
            loadData() {
                alert.loading();
                this.axios.get(apiPath('admin_list_jeux'))
                    .then(response => this.items = response.data)
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux'))
                    .finally(() => alert.stopLoading());
            },
            displayJeuModal(jeu) {
                this.$store.dispatch('modal/create', {
                    component: DisplayJeuModal,
                    props: {jeu: jeu}
                });
            },
            editJeuModal(jeu) {
                this.$store.dispatch('modal/create', {
                    component: AdminEditJeuModal,
                    props: {jeu: jeu, callback: () => this.loadData()}
                });
            },
            deleteJeu(jeu) {
                alert.confirm('Supprimer le jeu ' + jeu.nom + ' ?', () => {
                    this.axios.delete(apiPath('admin_delete_jeu', {jeu: jeu.id}))
                        .then(response => {
                            this.$toaster.success('Jeu ' + (response.data.softDelete ? 'archivé' : 'supprimé') + ' avec succès');
                            this.loadData();
                        })
                        .catch(() => this.$toaster.error('Impossible de supprimer le jeu'));
                });
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .thumbnail-object-image {
        max-width: 40px;
        max-height: 40px;
    }
</style>
